import { prismicClient } from '../prismic';
import { Environment } from './environment.model';

export const environment: Environment = {
    name: 'DEV',
    production: false,
    prismic: {
        client: prismicClient
    },
    stripe: {
        pubKey: 'pk_test_51OojaKDloolsOrHliYXvSHIePlHxz9DLUPeptDzZqyXWn4eMQvCc9pTfR00192TPcFyimMgsXYDkTECvNgsvW2xv00orvC71I5',
        productId: 'prod_QjX7KmqTxgeJY1'
    },
    backend: 'https://dev-dot-ralphys-ranch.ey.r.appspot.com/v1',
    baseUrl: 'https://test.ralphysranch.com',
    title: `Ralphy's Ranch`,
    stage: 'DEV'
};
