import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import AOS from 'aos';
import { environment } from '../environments/environment';
import { GlobalContent, GlobalContentService } from './services/global-content.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
    globalContent?: GlobalContent;
    private readonly globalContentService = inject(GlobalContentService);
    private readonly router = inject(Router);

    isDonationPage(): boolean {
        return this.router.url.startsWith('/patenschaft') || this.router.url.startsWith('/spenden');
    }

    ngOnInit() {
        this.globalContentService.getGlobalContent().subscribe((globalContent) => {
            if (globalContent) {
                this.globalContent = globalContent;
            }
        });
    }

    ngAfterViewInit(): void {
        AOS.init();

        setTimeout(() => {
            AOS.refresh();
        }, 500);
    }

    protected readonly environment = environment;
}
