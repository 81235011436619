import { Injectable } from '@angular/core';
import { GroupField, ImageField, KeyTextField, LinkField, PrismicDocument, RichTextField, SelectField, TitleField } from '@prismicio/client';
import { BehaviorSubject, from, map } from 'rxjs';
import { environment } from '../../environments/environment';

const GLOBAL_CONTENT = 'global-content';
const GLOBAL_CONTENT_UID = 'global-content';

@Injectable({
    providedIn: 'root'
})
export class GlobalContentService {
    private globalContent: BehaviorSubject<GlobalContent | undefined> = new BehaviorSubject<GlobalContent | undefined>(undefined);

    constructor() {
        this.fetchGlobalContent().subscribe((globalContent) => {
            this.globalContent.next(globalContent);
        });
    }

    getGlobalContent(): BehaviorSubject<GlobalContent | undefined> {
        return this.globalContent;
    }

    private fetchGlobalContent() {
        const previewValue = sessionStorage.getItem('preview');
        const ref = previewValue ? { ref: previewValue } : {};
        return from(environment.prismic.client.getByUID<PrismicDocument<GlobalContent>>(GLOBAL_CONTENT, GLOBAL_CONTENT_UID, { ...ref })).pipe(
            map((result) => {
                return result.data;
            })
        );
    }
}

export type GlobalContent = {
    navigation_bar_menu_items: GroupField<MenuItem>;
    navigation_bar_menu_action_items: GroupField<MenuItem>;
    footer_contact_title: TitleField;
    footer_contact_description: RichTextField;
    footer_contact_actions: GroupField<MenuItem>;
    footer_title: TitleField;
    footer_description: RichTextField;
    footer_button_link: LinkField;
    footer_button_text: KeyTextField;
    footer_links_title: KeyTextField;
    footer_links: GroupField<MenuItem>;
    footer_contact_address: KeyTextField;
    footer_contact_email: KeyTextField;
    footer_links_legal: GroupField<MenuItem>;
    footer_social_links: GroupField<SocialLink>;
    partnership_step_1_title: RichTextField;
    partnership_step_2_title: RichTextField;
    partnership_step_3_title: RichTextField;
    partnership_step_4_title: RichTextField;
    partnership_step_5_title: RichTextField;
    partnership_step_1_description: RichTextField;
    partnership_step_2_description: RichTextField;
    partnership_step_3_description: RichTextField;
    partnership_step_4_description: RichTextField;
    partnership_step_5_description: RichTextField;
    donation_step_1_title: RichTextField;
    donation_step_2_title: RichTextField;
    donation_step_3_title: RichTextField;
    donation_step_1_description: RichTextField;
    donation_step_2_description: RichTextField;
    donation_step_3_description: RichTextField;
    partnership_step_1_title_new: RichTextField;
    partnership_step_2_title_new: RichTextField;
    partnership_step_3_title_new: RichTextField;
    partnership_step_4_title_new: RichTextField;
    partnership_step_1_description_new: RichTextField;
    partnership_step_2_description_new: RichTextField;
    partnership_step_3_description_new: RichTextField;
    partnership_step_4_description_new: RichTextField;
};

export type MenuItem = {
    title: KeyTextField;
    link: LinkField;
    style?: SelectField;
};

export type SocialLink = {
    icon: ImageField;
    link: LinkField;
};
