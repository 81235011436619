import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { Params, RouterLink, RouterLinkActive } from '@angular/router';
import { FilledLinkToMediaField, LinkField, SelectField } from '@prismicio/client';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ModalService } from '../../../services/modal.service';
import { ModalComponent } from '../modal/modal.component';

@Component({
    selector: 'app-link',
    standalone: true,
    imports: [CommonModule, RouterLink, RouterLinkActive],
    templateUrl: './link.component.html',
    styleUrl: './link.component.scss'
})
export class LinkComponent {
    @Input() link?: LinkField | any;
    @Input() action?: SelectField;
    @Input() anker?: string;
    @Input() routerLinkActive: string = '';
    @Input() cssClass?: string;
    @Input() showArrow = false;
    @Input() whiteArrow = false;
    protected readonly alert = alert;

    private modalService = inject(ModalService);
    bsModalService = inject(BsModalService);
    private bsModalRef?: BsModalRef;
    @Input() queryParams?: Params | undefined;

    openModal(link: FilledLinkToMediaField) {
        this.modalService.getModal(link?.id).subscribe({
            next: (res) => {
                const payload: ModalOptions = {
                    initialState: {
                        data: res
                    },
                    class: 'modal-lg'
                };
                this.bsModalService.show(ModalComponent, payload);
            }
        });
    }
}
